<template>
  <div>
    <div class="box">
      <el-page-header @back="$router.go(-1)">
        <template slot="content" v-if="id">
          <div class="top-button">
            <el-button
              @click="toEx('previous')"
              class="previous"
              :type="ruleForm.previous_id ? 'primary' : 'info'"
              >{{ $t("examination.previous") }}</el-button
            >
            <el-button
              @click="toEx('next')"
              class="next"
              :type="ruleForm.next_id ? 'primary' : 'info'"
              >{{ $t("examination.next") }}</el-button
            >
          </div>
        </template>
      </el-page-header>
    </div>
    <el-card class="content mt20">
      <el-form
        label-position="left"
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <!--
        <el-form-item :label="$t('examination.title')" prop="title">
          <el-input v-model="ruleForm.title"></el-input>
        </el-form-item>
      -->
        <el-form-item :label="$t('examination.type')" prop="type">
          <el-radio-group size="medium" v-model="ruleForm.type">
            <el-radio :label="1">{{ type(1) }}</el-radio>
            <el-radio :label="3">{{ type(3) }}</el-radio>
            <el-radio :label="2">{{ type(2) }}</el-radio>
            <el-radio :label="4">{{ type(4) }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('examination.level')" prop="level">
          <el-radio-group size="medium" v-model="ruleForm.level">
            <el-radio :label="1">{{ level(1) }}</el-radio>
            <el-radio :label="2">{{ level(2) }}</el-radio>
            <el-radio :label="3">{{ level(3) }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          :class="ruleForm.type === 1 || ruleForm.type === 3 ? 'show' : 'hide'"
          :label="$t('examination.bool')"
          prop="bool"
        >
          <!-- ruleForm.type===1 || ruleForm.type===3 --->
          <el-radio-group size="medium" v-model="ruleForm.bool">
            <el-radio :label="0">{{ bool(0) }}</el-radio>
            <el-radio :label="1">{{ bool(1) }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('examination.order')" prop="order">
          <el-input
            @input="
              (res) => {
                this.ruleForm.order = +res;
              }
            "
            @mousewheel.native.prevent
            @DOMMouseScroll.native.prevent
            type="number"
            placeholder="정렬순이 높은 건이 우선 노출됩니다."
            v-model="ruleForm.order"
          ></el-input>
        </el-form-item>
        <!--
        <el-form-item :label="$t('examination.index')" prop="index">
          <el-input v-model="ruleForm.index"></el-input>
        </el-form-item>
        -->
        <el-form-item :label="$t('examination.content')" prop="content">
          <div v-if="load">
            <SummerNote
              :id="'summernote1' + new Date().getTime()"
              v-model="ruleForm.content"
            />
          </div>
        </el-form-item>
        <el-form-item :label="$t('examination.explain')" prop="explain">
          <div v-if="load">
            <SummerNote
              :id="'summernote2' + new Date().getTime()"
              v-model="ruleForm.explain"
            />
          </div>
        </el-form-item>
        <el-form-item :label="$t('examination.status')" prop="status">
          <el-radio-group size="medium" v-model="ruleForm.status">
            <el-radio :label="1">{{ status(1) }}</el-radio>
            <el-radio :label="0">{{ status(0) }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item
          v-if="ruleForm.type === 2 || ruleForm.type === 4"
          :label="$t('examination.answer')"
          prop="answer"
        >
          <el-input type="textarea" v-model="ruleForm.answer"></el-input>
          <!-- <div v-if="load">
            <SummerNote
              :id="'summernote3' + new Date().getTime()"
              v-model="ruleForm.answer"
            />
          </div> -->
        </el-form-item>
      </el-form>
      <el-button class="btn" type="primary" @click="submitForm('ruleForm')">{{
        $t("btnTip.submit")
      }}</el-button>
    </el-card>
  </div>
</template>
<script>
import SummerNote from "@/components/summernote";
import {
  createExamination,
  editExamination,
  examinationDetail,
} from "@/api/index";
export default {
  name: "editExamination",
  components: { SummerNote },
  data() {
    return {
      ruleForm: {
        title: "",
        status: 1,
        type: 1,
        level: 1,
        content: "",
        explain: "",
        order: 0,
        answer: "",
        bool: "",
        section_id: +this.$route.params.sectionId,
      },
      rules: {
        // title: [
        //   {
        //     required: true,
        //     message: this.$t("examination").title,
        //     trigger: "blur",
        //   },
        // ],
        type: [
          {
            required: true,
            message: this.$t("examination").type,
            trigger: "blur",
          },
        ],
        level: [
          {
            required: true,
            message: this.$t("examination").level,
            trigger: "blur",
          },
        ],
        content: [
          {
            required: true,
            message: this.$t("examination").content,
            trigger: "blur",
          },
        ],
        explain: [
          {
            required: true,
            message: this.$t("examination").explain,
            trigger: "blur",
          },
        ],
        order: [
          {
            required: true,
            message: this.$t("examination").order,
            trigger: "blur",
          },
        ],
        answer: [
          {
            required: true,
            message: this.$t("examination").answer,
            trigger: "blur",
          },
        ],
        bool: [
          {
            required: true,
            message: this.$t("examination").bool,
            trigger: "blur",
          },
        ],
        status: [
          {
            required: true,
            message: this.$t("examination").status,
            trigger: "blur",
          },
        ],
      },
      load: false,
    };
  },
  computed: {
    id() {
      return Number.isNaN(Number(this.$route.query.id))
        ? undefined
        : Number(this.$route.query.id);
    },
    content() {
      return this.id === undefined
        ? this.$t("btnTip").add
        : this.$t("btnTip").edit;
    },
  },
  watch: {
    "ruleForm.type"(newv) {
      if (newv === 1) {
        this.rules.bool[0].required = true;
        this.rules.answer[0].required = false;
      } else if (newv === 2) {
        this.rules.bool[0].required = false;
        this.rules.answer[0].required = true;
      } else if (newv === 3) {
        this.rules.bool[0].required = true;
        this.rules.answer[0].required = false;
      } else {
        this.rules.bool[0].required = false;
        this.rules.answer[0].required = true;
      }
    },
    id() {
      this.load = false;
      let params = JSON.parse(
        this.$store.state.paramsList[this.$store.state.paramsList.length - 1]
      );
      this.get_info(params);
    },
  },
  methods: {
    bool(bool) {
      switch (bool) {
        case 0:
          return this.$t("examination.bool0");
        case 1:
          return this.$t("examination.bool1");
      }
    },
    type(type) {
      switch (type) {
        case 1:
          return this.$t("examination.type1");
        case 2:
          return this.$t("examination.type2");
        case 3:
          return this.$t("examination.type3");
        case 4:
          return this.$t("examination.type4");
      }
    },
    level(level) {
      switch (level) {
        case 1:
          return this.$t("examination.level1");
        case 2:
          return this.$t("examination.level2");
        case 3:
          return this.$t("examination.level3");
      }
    },
    status(status) {
      switch (status) {
        case 0:
          return this.$t("examination.status0");
        case 1:
          return this.$t("examination.status1");
      }
    },
    async submitForm(formName) {
      await this.$refs[formName].validate();
      let ruleForm = { ...this.ruleForm };
      if (ruleForm.type === 1 || ruleForm.type === 3) {
        delete ruleForm.answer;
      } else if (ruleForm.type === 2 || ruleForm.type === 4) {
        delete ruleForm.bool;
      }
      if (this.id === undefined) {
        await createExamination(ruleForm);
      } else {
        await editExamination({ id: this.id, ...ruleForm });
      }
      this.$router.go(-1);
    },
    async get_info(e) {
      const information = await examinationDetail(this.id, {
        ...e.filter,
        ordering: e.ordering,
      });
      this.ruleForm = { ...information };
      this.load = true;
    },
    toEx(type) {
      if (type === "previous" && this.ruleForm.previous_id) {
        this.$router.replace(
          `/category/subject/${this.$route.params.subjectId}/chapter/${this.$route.params.chapterId}/section/${this.$route.params.sectionId}/examination/edit?id=${this.ruleForm.previous_id}`
        );
      } else if (type === "next" && this.ruleForm.next_id) {
        this.$router.replace(
          `/category/subject/${this.$route.params.subjectId}/chapter/${this.$route.params.chapterId}/section/${this.$route.params.sectionId}/examination/edit?id=${this.ruleForm.next_id}`
        );
      }
    },
  },
  async created() {
    if (this.id === undefined) {
      this.load = true;
      return;
    }
    let params = JSON.parse(
      this.$store.state.paramsList[this.$store.state.paramsList.length - 1]
    );
    this.get_info(params);
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-page-header__content {
  .next,
  .previous {
    height: 25px;
    line-height: 25px;
    padding: 0 20px;
  }
}
.hide {
  display: none;
}
</style>
